// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var Locale = {
  enUS: "en-US"
};

var _map = {"long":"long","short":"short"};

function compactDisplayToJs(param) {
  return param;
}

function compactDisplayFromJs(param) {
  return _map[param];
}

var _map$1 = {"USD":"USD"};

function currencyToJs(param) {
  return param;
}

function currencyFromJs(param) {
  return _map$1[param];
}

var _map$2 = {"symbol":"symbol","narrowSymbol":"narrowSymbol","code":"code","name":"name"};

function currencyDisplayToJs(param) {
  return param;
}

function currencyDisplayFromJs(param) {
  return _map$2[param];
}

var _map$3 = {"accounting":"accounting","standard":"standard"};

function currencySignToJs(param) {
  return param;
}

function currencySignFromJs(param) {
  return _map$3[param];
}

var _map$4 = {"standard":"standard","scientific":"scientific","engineering":"engineering","compact":"compact"};

function notationToJs(param) {
  return param;
}

function notationFromJs(param) {
  return _map$4[param];
}

var _map$5 = {"auto":"auto","never":"never","always":"always","exceptZero":"exceptZero"};

function signDisplayToJs(param) {
  return param;
}

function signDisplayFromJs(param) {
  return _map$5[param];
}

var _map$6 = {"decimal":"decimal","currency":"currency","percent":"percent","unit":"unit"};

function styleToJs(param) {
  return param;
}

function styleFromJs(param) {
  return _map$6[param];
}

var InitOptions = {
  compactDisplayToJs: compactDisplayToJs,
  compactDisplayFromJs: compactDisplayFromJs,
  currencyToJs: currencyToJs,
  currencyFromJs: currencyFromJs,
  currencyDisplayToJs: currencyDisplayToJs,
  currencyDisplayFromJs: currencyDisplayFromJs,
  currencySignToJs: currencySignToJs,
  currencySignFromJs: currencySignFromJs,
  notationToJs: notationToJs,
  notationFromJs: notationFromJs,
  signDisplayToJs: signDisplayToJs,
  signDisplayFromJs: signDisplayFromJs,
  styleToJs: styleToJs,
  styleFromJs: styleFromJs
};

var NumberFormat = {
  InitOptions: InitOptions
};

exports.Locale = Locale;
exports.NumberFormat = NumberFormat;
/* No side effect */
