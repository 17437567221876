// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var newLineRe = /\n/;

var newLineReGlobal = /\n/g;

var startingConsecutiveNewLinesReGlobal = /^\n+/g;

var startAndEndWithConsecutiveNewLinesReGlobal = /^\n+$/m;

var nonNewLineRe = /[^\n]/;

function makeNewLinesRegex(flags, numNewLinesPerEnter) {
  return new RegExp("\\n{" + (String(numNewLinesPerEnter) + "}"), flags);
}

var tokenRe = /[^\s]+/;

var googleSheetsRe = new RegExp("^https:\\/\\/docs.google.com\\/spreadsheets\\/d\\/([\\w-]+)(\\/edit)?");

var urlRe = new RegExp("^https?:\\/\\/.+");

function isUrl(str) {
  return urlRe.test(str);
}

function endsWithNumber(str) {
  return /\d+$/.test(str);
}

exports.newLineRe = newLineRe;
exports.newLineReGlobal = newLineReGlobal;
exports.startingConsecutiveNewLinesReGlobal = startingConsecutiveNewLinesReGlobal;
exports.startAndEndWithConsecutiveNewLinesReGlobal = startAndEndWithConsecutiveNewLinesReGlobal;
exports.nonNewLineRe = nonNewLineRe;
exports.makeNewLinesRegex = makeNewLinesRegex;
exports.tokenRe = tokenRe;
exports.googleSheetsRe = googleSheetsRe;
exports.urlRe = urlRe;
exports.isUrl = isUrl;
exports.endsWithNumber = endsWithNumber;
/* googleSheetsRe Not a pure module */
