// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Decco = require("decco/src/Decco.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Belt_Result = require("rescript/lib/js/belt_Result.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Js_null_undefined = require("rescript/lib/js/js_null_undefined.js");
var Util$SmartcutsMacros = require("./Util.bs.js");
var StringUtils$SmartcutsMacros = require("./StringUtils.bs.js");

function getExn(msg, result) {
  if (result.TAG === /* Ok */0) {
    return result._0;
  }
  console.error(result._0);
  return Util$SmartcutsMacros.logAndRaiseJsError("decco decoding error: " + msg);
}

function timeoutId_encode(timeoutId) {
  return timeoutId;
}

function timeoutId_decode(json) {
  var floatNum = Js_json.decodeNumber(json);
  if (floatNum !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: floatNum | 0
          };
  } else {
    return Decco.error(undefined, "Couldn't decode json into timeoutId; unrecognized json " + JSON.stringify(json), json);
  }
}

function intervalId_encode(intervalId) {
  return intervalId;
}

function intervalId_decode(json) {
  var floatNum = Js_json.decodeNumber(json);
  if (floatNum !== undefined) {
    return {
            TAG: /* Ok */0,
            _0: floatNum | 0
          };
  } else {
    return Decco.error(undefined, "Couldn't decode json into intervalId; unrecognized json " + JSON.stringify(json), json);
  }
}

function MakeDerivingAbstractDeccoFns(DerivingAbstractDecco) {
  var t_encode = function (prim) {
    return prim;
  };
  var t_decode = function (encoded) {
    return {
            TAG: /* Ok */0,
            _0: encoded
          };
  };
  return {
          t_encode: t_encode,
          t_decode: t_decode
        };
}

function MakePolymorphicWithDeccoFns(PolymorphicDecco) {
  var tToJs = PolymorphicDecco.tToJs;
  var tFromJs = PolymorphicDecco.tFromJs;
  var t_encode = Curry.__1(tToJs);
  var t_decode = function (encoded) {
    var str = Js_json.decodeString(encoded);
    if (str === undefined) {
      return Decco.error(undefined, "Couldn't decode json into " + PolymorphicDecco.typeName, encoded);
    }
    var poly = Curry._1(tFromJs, str);
    if (poly !== undefined) {
      return {
              TAG: /* Ok */0,
              _0: Caml_option.valFromOption(poly)
            };
    } else {
      return Decco.error(undefined, "Couldn't decode json into " + (PolymorphicDecco.typeName + ("; unrecognized token " + StringUtils$SmartcutsMacros.quote(str))), encoded);
    }
  };
  return {
          tToJs: tToJs,
          tFromJs: tFromJs,
          t_encode: t_encode,
          t_decode: t_decode
        };
}

function unitToUnit_encode(param) {
  return "() => ()";
}

function unitToUnit_decode(encoded) {
  var str = Js_json.decodeString(encoded);
  if (str !== undefined) {
    if (str === "() => ()") {
      return {
              TAG: /* Ok */0,
              _0: (function (param) {
                  
                })
            };
    } else {
      return Decco.error(undefined, "Expected '() => ()' json string, but got " + StringUtils$SmartcutsMacros.quote(str), encoded);
    }
  } else {
    return Decco.error(undefined, "Couldn't decode json into unitToUnit", encoded);
  }
}

function MakeJSONDataWithDeccoFns(JSONData) {
  var t_decode = function (encoded) {
    return {
            TAG: /* Ok */0,
            _0: encoded
          };
  };
  return {
          t_decode: t_decode
        };
}

function decodable_encode(encoder_data, v) {
  if (v.TAG === /* Decoded */0) {
    return [
            "Decoded",
            Curry._1(encoder_data, v._0)
          ];
  } else {
    return [
            "UnableToDecode",
            v._0
          ];
  }
}

function decodable_decode(decoder_data, v) {
  var jsonArr = Js_json.classify(v);
  if (typeof jsonArr === "number") {
    return Decco.error(undefined, "Not a variant", v);
  }
  if (jsonArr.TAG !== /* JSONArray */3) {
    return Decco.error(undefined, "Not a variant", v);
  }
  var jsonArr$1 = jsonArr._0;
  if (jsonArr$1.length === 0) {
    return Decco.error(undefined, "Expected variant, found empty array", v);
  }
  var tagged = jsonArr$1.map(Js_json.classify);
  var match = Belt_Array.getExn(tagged, 0);
  if (typeof match !== "number" && match.TAG === /* JSONString */0) {
    switch (match._0) {
      case "Decoded" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0 = Curry._1(decoder_data, Belt_Array.getExn(jsonArr$1, 1));
          if (v0.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* Decoded */0,
                      _0: v0._0
                    }
                  };
          }
          var e = v0._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
      case "UnableToDecode" :
          if (tagged.length !== 2) {
            return Decco.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$1 = {
            TAG: /* Ok */0,
            _0: Belt_Array.getExn(jsonArr$1, 1)
          };
          if (v0$1.TAG === /* Ok */0) {
            return {
                    TAG: /* Ok */0,
                    _0: {
                      TAG: /* UnableToDecode */1,
                      _0: v0$1._0
                    }
                  };
          }
          var e$1 = v0$1._0;
          return {
                  TAG: /* Error */1,
                  _0: {
                    path: "[0]" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
      default:
        
    }
  }
  return Decco.error(undefined, "Invalid variant constructor", Belt_Array.getExn(jsonArr$1, 0));
}

function getDecodedExn(v) {
  if (v.TAG === /* Decoded */0) {
    return v._0;
  } else {
    return Util$SmartcutsMacros.logAndRaiseJsError("Couldn't decode json into data; unrecognized json " + JSON.stringify(v._0));
  }
}

function t_encode(v) {
  return Js_dict.fromArray([
              [
                "path",
                Decco.stringToJson(v.path)
              ],
              [
                "message",
                Decco.stringToJson(v.message)
              ],
              [
                "value",
                v.value
              ]
            ]);
}

function t_decode(v) {
  var dict = Js_json.classify(v);
  if (typeof dict === "number") {
    return Decco.error(undefined, "Not an object", v);
  }
  if (dict.TAG !== /* JSONObject */2) {
    return Decco.error(undefined, "Not an object", v);
  }
  var dict$1 = dict._0;
  var path = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "path"), null));
  if (path.TAG === /* Ok */0) {
    var message = Decco.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(dict$1, "message"), null));
    if (message.TAG === /* Ok */0) {
      var value = {
        TAG: /* Ok */0,
        _0: Belt_Option.getWithDefault(Js_dict.get(dict$1, "value"), null)
      };
      if (value.TAG === /* Ok */0) {
        return {
                TAG: /* Ok */0,
                _0: {
                  path: path._0,
                  message: message._0,
                  value: value._0
                }
              };
      }
      var e = value._0;
      return {
              TAG: /* Error */1,
              _0: {
                path: ".value" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = message._0;
    return {
            TAG: /* Error */1,
            _0: {
              path: ".message" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = path._0;
  return {
          TAG: /* Error */1,
          _0: {
            path: ".path" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function fromDeccoDecodeError(param) {
  return {
          path: param.path,
          message: param.message,
          value: param.value
        };
}

var SerializableDeccoDecodeError = {
  t_encode: t_encode,
  t_decode: t_decode,
  fromDeccoDecodeError: fromDeccoDecodeError
};

function t_encode$1(encode, nullable) {
  if (nullable == null) {
    return null;
  } else {
    return Curry._1(encode, nullable);
  }
}

function t_decode$1(decode, json) {
  var maybeDecoded = Belt_Option.flatMap(Js_json.decodeNull(json), (function (prim) {
          if (prim === null) {
            return ;
          } else {
            return Caml_option.some(prim);
          }
        }));
  if (maybeDecoded !== undefined) {
    return Belt_Result.map(Curry._1(decode, Caml_option.valFromOption(maybeDecoded)), (function (v) {
                  return Js_null_undefined.fromOption(Caml_option.some(v));
                }));
  } else {
    return {
            TAG: /* Ok */0,
            _0: null
          };
  }
}

var Nullable = {
  t_encode: t_encode$1,
  t_decode: t_decode$1
};

exports.getExn = getExn;
exports.timeoutId_encode = timeoutId_encode;
exports.timeoutId_decode = timeoutId_decode;
exports.intervalId_encode = intervalId_encode;
exports.intervalId_decode = intervalId_decode;
exports.MakeDerivingAbstractDeccoFns = MakeDerivingAbstractDeccoFns;
exports.MakePolymorphicWithDeccoFns = MakePolymorphicWithDeccoFns;
exports.unitToUnit_encode = unitToUnit_encode;
exports.unitToUnit_decode = unitToUnit_decode;
exports.MakeJSONDataWithDeccoFns = MakeJSONDataWithDeccoFns;
exports.decodable_encode = decodable_encode;
exports.decodable_decode = decodable_decode;
exports.getDecodedExn = getDecodedExn;
exports.SerializableDeccoDecodeError = SerializableDeccoDecodeError;
exports.Nullable = Nullable;
/* Util-SmartcutsMacros Not a pure module */
