// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Ord = require("ord");
var Caml = require("rescript/lib/js/caml.js");
var List = require("rescript/lib/js/list.js");
var $$Array = require("rescript/lib/js/array.js");
var Curry = require("rescript/lib/js/curry.js");
var $$String = require("rescript/lib/js/string.js");
var Js_math = require("rescript/lib/js/js_math.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_array = require("rescript/lib/js/caml_array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Intl$SmartcutsMacros = require("./Intl.bs.js");
var Util$SmartcutsMacros = require("./Util.bs.js");
var RegExUtils$SmartcutsMacros = require("./RegExUtils.bs.js");
var OptionUtils$SmartcutsMacros = require("./OptionUtils.bs.js");

((require('css.escape')));

function withOrd(n) {
  return String(n) + Ord(n);
}

function isNewLine(s) {
  if (s.length === 1) {
    return s.charCodeAt(0) === 10.0;
  } else {
    return false;
  }
}

function quote(str) {
  return "\"" + (str + "\"");
}

function indent(indentWithOpt, times) {
  var indentWith = indentWithOpt !== undefined ? indentWithOpt : "  ";
  return $$Array.fold_left((function (indentation, indentWith) {
                return indentation + indentWith;
              }), "", Caml_array.make(times, indentWith));
}

function repeat(times, str) {
  return Belt_Array.reduce(Caml_array.make(times, str), "", (function (accumStr, s) {
                return accumStr + s;
              }));
}

function reverse(s) {
  return $$String.concat("", Belt_List.fromArray(Array.from(s).reverse()));
}

var join = $$String.concat;

function split(on, s) {
  return Belt_List.fromArray(s.split(on));
}

function slice(from, to_, str) {
  return str.slice(from, to_);
}

function endsWith(s1, s2) {
  var s1Length = s1.length;
  return s2 === slice(s1Length - s2.length | 0, s1Length, s1);
}

function beginsWith(s1, s2) {
  return s1.indexOf(s2) === 0;
}

function capitalizeFirstLetter(s) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

function removeTrailingSuffix(str, trailingSuffix) {
  if (endsWith(str, trailingSuffix)) {
    return slice(0, str.length - trailingSuffix.length | 0, str);
  } else {
    return Util$SmartcutsMacros.logAndRaiseJsError("removeTrailingSuffix tried removing " + (quote(trailingSuffix) + (" from the end of " + (quote(str) + " but it is not the trailing suffix"))));
  }
}

function removeLeadingPrefix(str, prefix) {
  if (beginsWith(str, prefix)) {
    return slice(prefix.length, str.length, str);
  } else {
    return Util$SmartcutsMacros.logAndRaiseJsError("removeLeadingPrefix tried removing " + (quote(prefix) + (" from the beginning of " + (quote(str) + (" but " + (quote(str) + (" doesn't begin with " + quote(prefix))))))));
  }
}

function commonPrefix(str1, str2) {
  var arr1 = Array.from(str1);
  var arr2 = Array.from(str2);
  var index = Belt_Array.reduce(Belt_Array.zip(arr1, arr2), [
          0,
          0
        ], (function (param, nextPair) {
            var index = param[1];
            var commonIndex = param[0];
            if (commonIndex < index || nextPair[0] !== nextPair[1]) {
              return [
                      commonIndex,
                      index + 1 | 0
                    ];
            } else {
              return [
                      commonIndex + 1 | 0,
                      index + 1 | 0
                    ];
            }
          }))[0];
  return OptionUtils$SmartcutsMacros.optionalString(str1.slice(0, index));
}

function joinArrayWithCommaAnd(strings) {
  var match = List.rev($$Array.to_list(strings));
  if (!match) {
    return "";
  }
  var otherWordsReversed = match.tl;
  var lastWord = match.hd;
  if (otherWordsReversed) {
    if (otherWordsReversed.tl) {
      return $$String.concat(", ", List.rev(otherWordsReversed)) + (", and" + lastWord);
    } else {
      return otherWordsReversed.hd + (" and " + lastWord);
    }
  } else {
    return lastWord;
  }
}

function pluralize(count, singularWord) {
  return singularWord + (
          count !== 0 && count === 1 ? "" : "s"
        );
}

var $$Uint8Array = {};

var $$TextEncoder = {
  $$Uint8Array: $$Uint8Array
};

var _map = {"Utf8":"utf-8","Utf16":"utf-16"};

var _revMap = {"utf-8":"Utf8","utf-16":"Utf16"};

function encoderToJs(param) {
  return _map[param];
}

function encoderFromJs(param) {
  return _revMap[param];
}

function Make(Codec) {
  var make = function (param) {
    return new TextDecoder(_map[Codec.encoder]);
  };
  return {
          make: make,
          decode: (function (prim0, prim1) {
              return prim0.decode(prim1);
            })
        };
}

function make(param) {
  return new TextDecoder(_map["Utf16"]);
}

function Utf16_decode(prim0, prim1) {
  return prim0.decode(prim1);
}

var Utf16 = {
  make: make,
  decode: Utf16_decode
};

var $$TextDecoder$1 = {
  encoderToJs: encoderToJs,
  encoderFromJs: encoderFromJs,
  Make: Make,
  Utf16: Utf16
};

function byteSize(unit, s) {
  var bytes = new TextEncoder().encode(s).length;
  if (unit) {
    return Js_math.ceil(bytes / 1000);
  } else {
    return bytes;
  }
}

function containsNewLine(s) {
  return RegExUtils$SmartcutsMacros.newLineRe.test(s);
}

function isComposedOfJustNewLines(s) {
  return /^\n+$/gm.test(s);
}

function myMatch(str, regex) {
  return Caml_option.nullable_to_opt(str.match(regex));
}

function countNewLines(s) {
  var matches = s.match(RegExUtils$SmartcutsMacros.newLineReGlobal);
  if (!(matches == null)) {
    return (
            (matches == null) ? undefined : Caml_option.some(matches)
          ).length;
  } else {
    return 0;
  }
}

function countConsecutiveStartingNewLines(s) {
  var matches = s.match(RegExUtils$SmartcutsMacros.startingConsecutiveNewLinesReGlobal);
  if (matches == null) {
    return 0;
  }
  var match = Belt_Array.get((matches == null) ? undefined : Caml_option.some(matches), 0);
  if (match === undefined) {
    return 0;
  }
  var firstDefinedMatch = Caml_option.valFromOption(match);
  if (firstDefinedMatch !== undefined) {
    return firstDefinedMatch.length;
  } else {
    return 0;
  }
}

function isToken(s) {
  return RegExUtils$SmartcutsMacros.tokenRe.test(s);
}

var doesSupportNativeReplaceAll = ('replaceAll' in String.prototype);

function replaceAll2_nonNative(s, replace, replaceWith) {
  return s.split(replace).join(replaceWith);
}

function replaceAll(replace, replaceWith, s) {
  if (doesSupportNativeReplaceAll) {
    return s.replaceAll(replace, replaceWith);
  } else {
    return replaceAll2_nonNative(s, replace, replaceWith);
  }
}

var nbsp = String.fromCharCode(160);

function reduce(str, init, f) {
  var len = str.length;
  var _indexOpt;
  var _valueOpt;
  var _param;
  while(true) {
    var indexOpt = _indexOpt;
    var valueOpt = _valueOpt;
    var index = indexOpt !== undefined ? indexOpt : 0;
    var value = valueOpt !== undefined ? Caml_option.valFromOption(valueOpt) : init;
    if (index >= len) {
      return value;
    }
    var x = str.charAt(index);
    var value$p = Curry._2(f, value, x);
    _param = undefined;
    _valueOpt = Caml_option.some(value$p);
    _indexOpt = index + 1 | 0;
    continue ;
  };
}

function escapeQuotes(param) {
  return replaceAll("\"", "\\\"", param);
}

var isAnyOf = $$Array.mem;

function replaceNonBreakingSpacesWithSpaces(s) {
  return s.replace(new RegExp(String.fromCharCode(160)), " ");
}

function getNonAlphaNumericSuffix(s) {
  var maybeSuffix = OptionUtils$SmartcutsMacros.map(Belt_Array.reduce(s.split(""), undefined, (function (maybeNonAlphaNumericSuffix, character) {
              if (/\w/.test(character)) {
                return maybeNonAlphaNumericSuffix;
              } else if (maybeNonAlphaNumericSuffix !== undefined) {
                return maybeNonAlphaNumericSuffix + character;
              } else {
                return character;
              }
            })), (function (suffixWithPossibleTrailingSpace) {
          return suffixWithPossibleTrailingSpace.replace(/\s+$/, "");
        }));
  if (maybeSuffix === "") {
    return ;
  } else {
    return maybeSuffix;
  }
}

var arg = {
  currency: "USD",
  currencyDisplay: "symbol",
  style: "currency",
  minimumFractionDigits: 2
};

var currencyFormat = (function (param) {
      return new (Intl.NumberFormat)(param, arg);
    })(Intl$SmartcutsMacros.Locale.enUS);

var numberFormat2 = new (Intl.NumberFormat)(Intl$SmartcutsMacros.Locale.enUS, {});

function splitIntoPrefixesInner(text, maybeLimit, param) {
  var tmp;
  if (maybeLimit !== undefined) {
    var partial_arg = /\s+/;
    tmp = (function (param) {
        return param.split(partial_arg, maybeLimit);
      });
  } else {
    var partial_arg$1 = /\s+/;
    tmp = (function (param) {
        return param.split(partial_arg$1);
      });
  }
  return Belt_Array.reduce(Belt_Array.keepMap(tmp(text), (function (x) {
                      return x;
                    })), [
                [],
                text
              ], (function (param, token) {
                  var remainingTextWithoutPrefix = param[1];
                  var possiblePrefixes = param[0];
                  var lastPrefix = Belt_Array.get(possiblePrefixes, Caml.caml_int_max(possiblePrefixes.length - 1 | 0, 0));
                  if (lastPrefix !== undefined) {
                    var indexOfToken = remainingTextWithoutPrefix.indexOf(token);
                    var textBetweenLastPrefixAndNextToken = remainingTextWithoutPrefix.slice(0, indexOfToken);
                    var nextRemainingTextWithoutPrefix = remainingTextWithoutPrefix.slice(indexOfToken + token.length | 0);
                    var nextPossiblePrefixes = $$Array.append(possiblePrefixes, [lastPrefix + (textBetweenLastPrefixAndNextToken + token)]);
                    return [
                            nextPossiblePrefixes,
                            nextRemainingTextWithoutPrefix
                          ];
                  }
                  var nextRemainingTextWithoutPrefix$1 = remainingTextWithoutPrefix.slice(token.length);
                  return [
                          [token],
                          nextRemainingTextWithoutPrefix$1
                        ];
                }))[0];
}

function splitIntoPrefixes(text) {
  return splitIntoPrefixesInner(text, undefined, undefined);
}

function splitIntoPrefixesAtMost(text, limit) {
  return splitIntoPrefixesInner(text, limit, undefined);
}

function stringifyListAsLengthProp(list) {
  return "{len: " + (String(List.length(list)) + "}");
}

exports.withOrd = withOrd;
exports.isNewLine = isNewLine;
exports.quote = quote;
exports.indent = indent;
exports.repeat = repeat;
exports.reverse = reverse;
exports.join = join;
exports.split = split;
exports.slice = slice;
exports.endsWith = endsWith;
exports.beginsWith = beginsWith;
exports.capitalizeFirstLetter = capitalizeFirstLetter;
exports.removeTrailingSuffix = removeTrailingSuffix;
exports.removeLeadingPrefix = removeLeadingPrefix;
exports.commonPrefix = commonPrefix;
exports.joinArrayWithCommaAnd = joinArrayWithCommaAnd;
exports.pluralize = pluralize;
exports.$$TextEncoder = $$TextEncoder;
exports.$$TextDecoder = $$TextDecoder$1;
exports.byteSize = byteSize;
exports.containsNewLine = containsNewLine;
exports.isComposedOfJustNewLines = isComposedOfJustNewLines;
exports.myMatch = myMatch;
exports.countNewLines = countNewLines;
exports.countConsecutiveStartingNewLines = countConsecutiveStartingNewLines;
exports.isToken = isToken;
exports.doesSupportNativeReplaceAll = doesSupportNativeReplaceAll;
exports.replaceAll2_nonNative = replaceAll2_nonNative;
exports.replaceAll = replaceAll;
exports.nbsp = nbsp;
exports.reduce = reduce;
exports.escapeQuotes = escapeQuotes;
exports.isAnyOf = isAnyOf;
exports.replaceNonBreakingSpacesWithSpaces = replaceNonBreakingSpacesWithSpaces;
exports.getNonAlphaNumericSuffix = getNonAlphaNumericSuffix;
exports.currencyFormat = currencyFormat;
exports.numberFormat2 = numberFormat2;
exports.splitIntoPrefixes = splitIntoPrefixes;
exports.splitIntoPrefixesAtMost = splitIntoPrefixesAtMost;
exports.stringifyListAsLengthProp = stringifyListAsLengthProp;
/*  Not a pure module */
